import { Button, Result } from "antd"
import { navigate } from "gatsby"
import * as React from "react"
// import {
//   withPrismicUnpublishedPreview,
//   componentResolverFromMap,
// } from "gatsby-plugin-prismic-previews"
// import { linkResolver } from "../utils/link-resolver"
import Layout from "../components/layout"
import PageContent from "../components/page-content"
// import { heading, paragraph, container } from "./404.module.css"

const NotFoundPage = () => {
  return (
    <Layout>
      <PageContent>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Back Home
            </Button>
          }
        />
      </PageContent>
    </Layout>
  )
}
export default NotFoundPage
// export default withPrismicUnpublishedPreview(NotFoundPage, [
//   {
//     repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
//     linkResolver,
//     componentResolver: componentResolverFromMap({
//       homepage: HomeTemplate,
//       page: PageTemplate,
//     }),
//   },
// ])
